import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";
import { moneyTypeOptions } from "../../../../commons/loan-application-constants";
import {
  getLabel,
  setFieldTouchedRecusively,
} from "../../../../utils/loan-application.utils";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
const IncomeDetailStep = ({ formik, handlePrevStep, handleSaveSection }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const onSaveStep = () => {
    if (!formik.values.income.items[0].incomeItems[0].source) {
      formik.setFieldTouched("income.items[0].incomeItems[0].source");
      return;
    }
    handleSaveSection();
  };

  const onPrevStep = () => {
    formik.setFieldValue("income", formik.initialValues.income);
    setFieldTouchedRecusively(formik, "income", false, false);
    handlePrevStep();
  };

  return (
    <Box classes={{ root: classes.container }}>
      <Box>
        <Typography>{getLabel(formik.values.income.incomeType)}</Typography>
      </Box>
      <Box>
        <TextField
          name="income.items[0].incomeItems[0].source"
          onChange={formik.handleChange}
          value={formik.values.income?.items?.at(0).incomeItems?.at(0).source}
          variant="outlined"
          label="Source Name"
          placeholder="*******"
          fullWidth
          InputLabelProps={{
            shrink: true,
            color: "primary",
          }}
          error={
            formik.touched?.income?.items?.at(0).incomeItems?.at(0).source &&
            Boolean(
              formik.errors?.income?.items?.at(0).incomeItems?.at(0).source
            )
          }
          helperText={
            formik.touched?.income?.items?.at(0).incomeItems?.at(0).source &&
            formik.errors?.income?.items?.at(0).incomeItems?.at(0).source
          }
          FormHelperTextProps={{
            style: { position: "absolute", bottom: "-20px" },
          }}
        />
      </Box>
      <Box>
        <BaseMoneyInput
          options={moneyTypeOptions}
          selectedOption={
            formik.values.income.items[0].incomeItems[0].frequency
          }
          setSelectedOption={(value) =>
            formik.setFieldValue(
              `income.items[0].incomeItems[0].frequency`,
              value
            )
          }
          onChange={(value) =>
            formik.setFieldValue(`income.items[0].incomeItems[0].amount`, value)
          }
          value={formik.values.income.items[0].incomeItems[0].amount}
        />
      </Box>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button endIcon={<Add />} onClick={onSaveStep}>
          Save
        </Button>
        <Button endIcon={<Close />} onClick={onPrevStep} customColor="danger">
          Delete
        </Button>
      </Grid>
    </Box>
  );
};

export default IncomeDetailStep;
